<template src="./customerreports.html"></template>
<style scoped src="./customerreports.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import applicationConstants from '@/constants/AppConstants.vue'
import reportServices from '@/services/ReportService.vue'
import Modal from '@/components/modal/notification/modal'
import utils from '@/utils/utils'
import moment from 'moment'
import JsonToExcel from 'vue-json-excel'
import Pagination from '@/components/vtable/vtpagination.vue'
import accountConstants from '@/constants/AccountConstants.vue'

export default {
    name: 'CustomerExtract',
    components: {
        Loading, Modal, Pagination, JsonToExcel
    },
    data () {
        return {
            accountStatus: "",
            accountSubStatus: "",
            province: "",
            isGoodCustomer: false,
            accountStatusList: accountConstants.accountStatus,
            accountSubStatusList: accountConstants.accountSubStatus,
            provincesList: accountConstants.provincesList,
            customerData: [],
            customerTableDataColumns: ['FirstName', 'LastName', 'Email', 'CellPhoneNo', 'SignUpDate', 'accountstatus', 'LastLogin',],
            customerTableOptions: {},
            enablePaginateData: applicationConstants.enableLazyLoading,
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            csvFields: {
                "UserID": "ID",
                "FirstName": "FirstName",
                "LastName": "LastName",
                "Email": "Email",
                "CellPhoneNo": "CellPhoneNo",
                "Address1": "Address1",
                "City": "City",
                "State": "State",
                "SignUpDate": "SignUpDate",
                "LChangedDate": "LChangedDate",
                "accountstatus": "accountstatus",
                "accountsubstatus": "accountsubstatus",
                "LastLogin": "LastLogin",
                "TimeZone": "TimeZone",
                "riskscore": "riskscore",
                "DOB": "DOB",
                "EmailStatus": "EmailStatus",
                "MobileStatus": "MobileStatus",
                "MRMSStatus": "MRMSStatus",
                "EIDStatus": "EIDStatus",
                "BankAccountChangedDate": "BankAccountChangedDate" 
            }
        }
    },
    methods: {
        getCustomerData () {
            this.loaderVisible = true
            reportServices.methods.GetCustomerReport({
                AccountStatus: this.accountStatus ? this.accountStatus : null,
                AccountSubStatus: this.accountSubStatus ? this.accountSubStatus : null,
                Province: this.province ? this.accountSubStatus : null,
                IsGoodCustomer: this.isGoodCustomer
            })
            .then(response => {
                this.customerData = response.csGetCustomerExtractResponse.CustomerData?.RptCustomerData
                this.customerData = this.customerData ? (Array.isArray(this.customerData) ? this.customerData: [this.customerData]) : []
                if (this.enablePaginateData) {
                    this.$refs.paginationRef.selectedPage = 1
                } else {
                    this.$refs.vueClientTable.setPage(1)
                }
                this.loaderVisible = false
            })
            .catch(err => {
                this.customerData = []
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        reponseModifier (customerData) {
            if (customerData) {
                customerData.forEach(customer => {
                    Object.keys(customer).map(key => customer[key] = customer[key]?._text ? customer[key]._text : null)
                })
            }
            return customerData ? customerData : []
        },
        clearAll () {
            this.accountStatus = ""
            this.accountSubStatus = ""
            this.province = ""
            this.isGoodCustomer = false
        },
        exportTableData () {
            if (this.customerData.length > 0) {
                return this.reponseModifier(JSON.parse(JSON.stringify(this.customerData)))
            } 
        }
    },
    computed: {
        filteredSubStatusList () {
            return this.accountSubStatusList.filter(subStatus => subStatus.AccountStatus === this.accountStatus)
        },
        customerTableData () {
            let customerData = []
            if (this.customerData && this.customerData.length > 0) {
                customerData = JSON.parse(JSON.stringify(this.customerData))
                if(this.enablePaginateData) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    customerData = customerData.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                customerData = this.reponseModifier(JSON.parse(JSON.stringify(customerData)))
                const updateTotalRecords = () => {
                    if(this.$refs.paginationRef) {
                        this.$refs.paginationRef.totalRecords = this.customerData?.length ?? 0
                    }
                }
                if (this.enablePaginateData) {
                    updateTotalRecords();
                }
            }
            return customerData
        }
    }
}
</script>